input:-internal-autofill-selected {
  background-color: #e1f4f6 !important;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
html {
  font-size: 16px;
}
.carousel {
  align-items: center;
  display: grid;
  font-family: system-ui;
  justify-content: center;
}
.carousel__list {
  align-items: center;
  display: grid;
  grid-row-gap: 1.25rem;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr auto;
  overflow: hidden;
  list-style-type: none;
}
.carousel__item {
  grid-column: 1;
  grid-row: 1;
  line-height: 1.2;
  text-align: center;
  transition: transform 0.2s;
}
.carousel__item.active {
  transform: translateX(0);
}
.carousel__item.left {
  transform: translateX(-110%);
}
.carousel__item.right {
  transform: translateX(110%);
}
.carousel__indicator {
  display: grid;
  grid-gap: 1rem;
  grid-row: 2;
  justify-self: center;
}
.carousel__dot {
  background-color: white;
  border-radius: 50%;
  border: 0.0625rem solid #696a6b;
  display: block;
  height: 0.5rem;
  width: 0.5rem;
}
.carousel__dot.active {
  background-color: #696a6b;
}
.carousel__citation {
  display: block;
  font-size: 0.875rem;
  font-style: normal;
  margin-bottom: 1.25rem;
}
.carousel__name {
  display: block;
  font-style: normal;
  font-weight: 600;
}
.carousel__quote {
  font-style: italic;
  line-height: 1.5;
}
